import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

// import { getFirebaseBackend } from '../../authUtils';

import { User } from "../models/auth.models";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: User;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Returns the current user
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Performs the auth
   * @param formData
   */
  login(credential) {
    console.log("logging in...");
    return this.http.post<any>(`${environment.apiUrl}login`, credential).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        console.log("user__service__response ::",user);
        if (user.verxid && user.verxid.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user.verxid));
          localStorage.setItem('token', JSON.stringify(user.verxid.token));
          this.currentUserSubject.next(user.verxid);
        }
        return user;
      })
    );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    // return getFirebaseBackend().registerUser(email, password).then((response: any) => {
    //     const user = response;
    //     return user;
    // });
    return;
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    // return getFirebaseBackend().forgetPassword(email).then((response: any) => {
    //     const message = response.data;
    //     return message;
    // });
    return;
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    this.currentUserSubject.next(null);
    this.router.navigate(['/account/login'])
  }
}
